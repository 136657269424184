import React, {forwardRef} from "react";
import {
   FormControl,
   Select
} from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import "./styles.css"

const CustomSelect = forwardRef(({
                                    name,
                                    onSelectChange,
                                    styles,
                                    styleSelect,
                                    borderRadius,
                                    size,
                                    placeholder,
                                    data,
                                    title,
                                    defaultValue,
                                    selected,
                                    icon,
                                    value,
                                    disabled,
                                    ...props
                                 }, ref) => {


   const handleChange = (event) => onSelectChange(event);

   return (
      <FormControl sx={styles}>
         <Select
            name={name}
            defaultValue={defaultValue}
            value={value}
            onChange={onSelectChange && handleChange}
            sx={styleSelect}
            size={size}
            disabled={disabled}
            native
            {...props}
            ref={ref}
            renderValue={(selected) => {
               if (selected.length === 0) {
                  return <em className="placeholder">{title}</em>;
               }
               return selected.join(', ');
            }}
            startAdornment={icon && <AccountCircleIcon sx={{ mr: 1 }} />}
         >
            <>
               <option selected={selected} disabled value={defaultValue}>
                  <em style={{ color: 'grey' }}>{title}</em>
               </option>
               {data &&
                  data.map((item, index) => (
                     <option key={index} value={item?.value}>
                        {item?.label}
                     </option>
                  ))}
            </>
         </Select>
      </FormControl>
   )
})
export default CustomSelect;
