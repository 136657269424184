import {useContext} from "react";
import {SpeechContext} from "../../store/context/SpeechContextProvider";

const useSpeechContext = () => {
   const speechContext = useContext(SpeechContext);

   const {
      statusSpeech,
      onSetCurrentQuestion,
      onSetInitSpeech,
      onSetStatusSpeech,
      setIsSpeechAssistantState,
      setActiveAnimationSpeechState,
      setIsSpeechState,
      currentSpeechStatus,
      previousSpeechStatus,
      onInitSpeech,
      isSpeechAssistant,
      isActiveAnimationSpeech,
      isSpeech,
      isAnimationSpeech,
      isAnimationSpeechAI,
      onSetIsAnimationSpeechAI,
      onSetIsAnimationSpeech,
      startSpeech,
      pauseSpeech,
      stopSpeech,
   } = speechContext;

   return {
      statusSpeech,
      onInitSpeech,
      currentSpeechStatus,
      previousSpeechStatus,
      isSpeechAssistant,
      isActiveAnimationSpeech,
      isSpeech,
      isAnimationSpeech,
      isAnimationSpeechAI,
      onSetCurrentQuestion,
      onSetInitSpeech,
      onSetStatusSpeech,
      setIsSpeechAssistantState,
      setActiveAnimationSpeechState,
      setIsSpeechState,
      onSetIsAnimationSpeechAI,
      onSetIsAnimationSpeech,
      startSpeech,
      pauseSpeech,
      stopSpeech,
   }
}
export default useSpeechContext;
