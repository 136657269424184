import useTestGeneratorViewModel from "../../view-models/admin/useTestGeneratorViewModel";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {ROUTE_ADMIN_CANDIDATE} from "../../data/constant/RouteConstants";
import useCandidateViewModel from "../../view-models/admin/useCandidateViewModel";
import useAuthViewModel from "../../view-models/useAuthViewModel";

const useAppliedCandidatesController = () => {
   const [appliedCandidatesData, setAppliedCandidatesData] = useState([]);
   const [paginatedData, setPaginatedData] = useState([])
   const [filter, setFilter] = useState('');
   const [page, setPage] = useState(1);
   const itemsPerPage = 10;

   const navigate = useNavigate();
   const {skillId} = useParams();
   const {skill} = useTestGeneratorViewModel();
   const {token} = useAuthViewModel();
   const {
      isSuccessCandidate,
      setCandidate,
      setCandidateTests,
      candidate,
      triggerFetchCandidateById,
   } = useCandidateViewModel();

   const handleFilterChange = (e) => {
      setFilter(e);
   }

   useEffect(() => {
      const filtered = skill?.data?.candidatesOnTest.filter((test) =>
         test?.name.toLowerCase().includes(filter.toLowerCase())
      );
      setAppliedCandidatesData(filtered);
      setPage(1)
   }, [filter, skill]);

   useEffect(() => {
      const start = (page - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      setPaginatedData(appliedCandidatesData?.slice(start, end));
   }, [page, appliedCandidatesData]);

   useEffect(() => {
      if (isSuccessCandidate) {
         setCandidateTests(candidate?.data?.test);
         setCandidate(candidate?.data);

         navigate(`${ROUTE_ADMIN_CANDIDATE.CANDIDATE_TEST}/${skillId}`)
      }
   }, [isSuccessCandidate, candidate]);

   const onHandleDetailApplicant = (candidateId) => {
      triggerFetchCandidateById(candidateId);
   }

   const fetchPdf = async (id, userName) => {
      try {
         const response = await fetch(`${process.env.REACT_APP_API_URL}/skill/test/pdf/${id}`, {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({reportType: 'detailed'})
         })

         const blob = await response.blob();
         const url = window.URL.createObjectURL(blob);
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute('download', `${userName}_${skill?.data?.name}.pdf`);
         document.body.appendChild(link);
         link.click();
         link.parentNode.removeChild(link);

      } catch (e) {
         throw new Error('Network response was not ok');
      }
   }

   return {
      skill,
      appliedCandidatesData,
      paginatedData,
      setPage,
      page,
      totalPages: Math.ceil(appliedCandidatesData?.length / itemsPerPage),
      handleFilterChange,
      onHandleDetailApplicant,
      fetchPdf
   }

}
export default useAppliedCandidatesController;
