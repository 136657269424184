import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';

const CustomSearch = ({onChange, placeholder}) => {
   return (
      <Paper
         elevation={0}
         onSubmit={(e) => e.preventDefault()}
         component="form"
         sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%' ,
            border: '1px solid grey',
            height: '40px'
      }}
      >
         <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
         </IconButton>
         <InputBase
            onChange={(e) => onChange(e.target.value)}
            sx={{ flex: 1 }}
            placeholder={placeholder}
            inputProps={{ 'aria-label': 'search google maps' }}
         />
      </Paper>
   )
}
export default CustomSearch;
