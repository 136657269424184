import React, {createContext, useRef, useState, useMemo} from 'react';

export const AppContext = createContext({
   videoRef: null,
   streamVideo: null,
   initStreamVideo: null,
   openModal: false,
   openAlert: false,
   errorAlert: false,
   isActiveAlert: false,
   messageAlert: '',
   severityAlert: null,
   timeOffAlert: 1000,
   isLoadingUploadVideo: false,
   enableSpeech: false,
   enableInitialSpeech: true,
   isVisibleSendCode: false,
   initTimerResendCode: false,
   timerSecondsResendCode: 30,
   companyValues: {},
   showVerify: false,
   isEditCompany: false,
   setInitStreamVideoState: () => null,
   setStreamVideoState: () => null,
   setOpenModalState: () => null,
   onSetOpenAlert: () => null,
   onSetIsLoadingUploadVideo: () => null,
   onEnableSpeech: () => null,
   onEnableInitialSpeech: () => null,
   closeCamera: () => null,
   setStateIsVisibleSendCode: () => null,
   setStateInitTimerResendCodde: () => null,
   setStateTimerSecondsResendCode: () => null,
   onSetCompanyValues: () => null,
   onSetShowVerify: () => null,
   onSetIsEditCompany: () => null,
});

const AppContextProvider = ({children}) => {
   const [initStreamVideo, setInitStreamVideo] = useState(null);
   const [streamVideo, setStreamVideo] = useState(null);
   const [openModal, setOpenModal] = useState(false);
   const [openAlert, setOpenAlert] = useState(false);
   const [errorAlert, setErrorAlert] = useState(false);
   const [isActiveAlert, setIsActiveAlert] = useState(false);
   const [messageAlert, setMessageAlert] = useState('');
   const [severityAlert, setSeverityAlert] = useState(null);
   const [timeOffAlert, setTimeOffAlert] = useState(1000);
   const [isLoadingUploadVideo, setIsLoadingUploadVideo] = useState(false);
   const [enableSpeech, setEnableSpeech] = useState(false);
   const [enableInitialSpeech, setEnableInitialSpeech] = useState(true);
   const [isVisibleSendCode, setIsVisibleSendCode] = useState(false);
   const [initTimerResendCode, setInitTimerResendCode] = useState(false);
   const [timerSecondsResendCode, setTimerSecondsResendCode] = useState(30);
   const [companyValues, setCompanyValues] = useState({});
   const [showVerify, setShowVerify] = useState(false);
   const [isEditCompany, setIsEditCompany] = useState(false);

   const videoRef = useRef(null);

   const onSetIsLoadingUploadVideo = (state) => setIsLoadingUploadVideo(state);

   const setStreamVideoState = (state) => {
      setStreamVideo(state)
   }

   const setInitStreamVideoState = (state) => {
      setInitStreamVideo(state);
   }

   const setOpenModalState = (state) => {
      setOpenModal(state);
   }

   const onSetOpenAlert = ({
                              isActiveAlert,
                              errorAlert,
                              messageAlert,
                              severityAlert,
                              time
                           }) => {
      setIsActiveAlert(isActiveAlert);
      setErrorAlert(errorAlert);
      setMessageAlert(messageAlert);
      setSeverityAlert(severityAlert);
      setTimeOffAlert(time)
   }

   const onEnableSpeech = (state) => {
      setEnableSpeech(state)
   }

   const onEnableInitialSpeech = (state) => {
      setEnableInitialSpeech(!state);
   }

   const closeCamera = () => {
      streamVideo?.getTracks().forEach(track => {
         track.stop();
      });
      initStreamVideo?.getTracks().forEach(track => {
         track.stop();
      });

      setInitStreamVideoState(null);
      setStreamVideoState(null);

      videoRef.current = null;
   }

   const setStateIsVisibleSendCode = (state) => {
      setIsVisibleSendCode(state)
   }

   const setStateInitTimerResendCodde = (state) => {
      setInitTimerResendCode(state)
   }

   const setStateTimerSecondsResendCode = (state) => {
      setTimerSecondsResendCode(state)
   }

   const onSetCompanyValues = ({companyName, companyId}) => {
     setCompanyValues({
        companyName: companyName,
        companyId: companyId
     });
   }

   const onSetShowVerify = (state) => {
      setShowVerify(state)
   }

   const onSetIsEditCompany = (state) => {
     setIsEditCompany(state)
   }

   const contextVideoCamValue = useMemo(() => ({
      videoRef,
      streamVideo,
      initStreamVideo,
      openModal,
      openAlert,
      errorAlert,
      isActiveAlert,
      messageAlert,
      severityAlert,
      timeOffAlert,
      isLoadingUploadVideo,
      enableSpeech,
      enableInitialSpeech,
      isVisibleSendCode,
      initTimerResendCode,
      timerSecondsResendCode,
      companyValues,
      showVerify,
      isEditCompany,
      setStreamVideoState,
      setInitStreamVideoState,
      setOpenModalState,
      onSetOpenAlert,
      onSetIsLoadingUploadVideo,
      onEnableSpeech,
      onEnableInitialSpeech,
      closeCamera,
      setStateIsVisibleSendCode,
      setStateInitTimerResendCodde,
      setStateTimerSecondsResendCode,
      onSetCompanyValues,
      onSetShowVerify,
      onSetIsEditCompany
   }), [
      videoRef,
      streamVideo,
      initStreamVideo,
      openModal,
      openAlert,
      errorAlert,
      isActiveAlert,
      messageAlert,
      severityAlert,
      timeOffAlert,
      isLoadingUploadVideo,
      enableSpeech,
      enableInitialSpeech,
      isVisibleSendCode,
      initTimerResendCode,
      timerSecondsResendCode,
      companyValues,
      showVerify,
      isEditCompany
   ]);

   return <AppContext.Provider value={contextVideoCamValue}>{children}</AppContext.Provider>
}

export default AppContextProvider;
