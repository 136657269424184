import {useEffect, useRef, useState} from "react";
import {ROUTE_INTERVIEW} from "../../data/constant/RouteConstants";
import {useNavigate} from "react-router-dom";
import useInterviewViewModel from "../../view-models/useInterviewViewModel";
import useAppContext from "../../presentation/hooks/useAppContext";
import useTestMultimediaViewModel from "../../view-models/useTestMultimediaViewModel";
// import {STATUS_QUESTION} from "../../data/constant/Constants";
import useSpeechContext from "../../presentation/hooks/useSpeechContext";

const useTestVideoController = () => {
   const [isCamera, setIsCamera] = useState(false);
   const [audioStream, setAudioStream] = useState([]);
   const [selectedDevice, setSelectedDevice] = useState('');
   const [videoDevices, setVideoDevices] = useState([]);
   const [selectedVideoDevice, setSelectedVideoDevice] = useState('');
   const [volume, setVolume] = useState(0);
   const [enableMedia, setEnableMedia] = useState(false);
   // const [currentAvailableTest, setCurrentAvailableTest] = useState(null);

   const navigate = useNavigate();
   const visualizerRef = useRef(null);

   const {
      interviewSettings,
      enableScreenAboutMeQuestions,
      enableScreenInterviewQuestions,
      indexQuestion,
      setInputAudioSelected,
      setInputVideoSelected,
      setStartInterview,
   } = useInterviewViewModel();

   const {
      streamVideo,
      initStreamVideo,
      videoRef,
      setStreamVideoState,
      setInitStreamVideoState
   } = useAppContext();

   const {onSetCurrentQuestion, onSetIsAnimationSpeech} = useSpeechContext();

   const {
      //State
      testId,
      secret,
      availableTests,
      //Fetch questions
      triggerGetTasksQuery,
      questionsData,
      isFetchingQuestions,
      isSuccessQuestions,
      //Fetch interviews
      triggerFetchInterviewTests,
      isSuccessFetchInterviewTests,
      interviewTests,
      //Fn state
      setCurrentQuestion,
      setQuestions,
      setIndexQuestion,
      setTotalQuestions,
      setStatusTimer,
      setAvailableTests,
      setSkillId,
   } = useTestMultimediaViewModel();

   useEffect(() => {
      if (indexQuestion === 0) {
         setIndexQuestion(1);
      }
   }, []);

   useEffect(() => {
      const enableCamera = async () => {
         try {
            await navigator.mediaDevices.getUserMedia({video: true});
            await navigator.mediaDevices.getUserMedia({audio: true});
         } catch (error) {
            console.error('Error accessing camera:', error);
         }
      };

      enableCamera();

      return () => {
         initStreamVideo?.getTracks().forEach(track => {
            track.stop();
         });
         setInitStreamVideoState(null);
      };
   }, [])

   useEffect(() => {
      return () => {
         if (streamVideo) {
            streamVideo?.getTracks().forEach(track => {
               track.stop();
            });
         }
      };
   }, [streamVideo]);

   useEffect(() => {
      const fetchVideoDevices = async () => {
         try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoInputDevices = devices.filter(device => device.kind === 'videoinput');
            setVideoDevices(videoInputDevices);
         } catch (error) {
            console.error('Error al obtener dispositivos de video:', error);
         }
      };

      const handleDeviceChange = () => fetchVideoDevices();

      fetchVideoDevices();

      navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);

      return () => {
         navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChange);
      };

   }, [videoDevices]);

   useEffect(() => {
      const checkDevices = async () => {
         try {
            const deviceInfo = await navigator.mediaDevices.enumerateDevices();
            let res = deviceInfo.filter(device => device.kind === 'audioinput');
            setAudioStream(res);
         } catch (error) {
            console.error('Error enumerating devices:', error);
         }
      };

      const handleDeviceChange = () => {
         checkDevices();
      }

      checkDevices();

      navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);

      return () => {
         navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChange);
      };
   }, [audioStream]);

   useEffect(() => {
      if (isSuccessFetchInterviewTests) {
         if (interviewTests.length > 0) {
            let testsToProcess = [...interviewTests];
            navigate(ROUTE_INTERVIEW.PREVIEW_ABOUT_ME_INTERVIEW);
            if (testsToProcess.length > 1 && !interviewSettings.mandatoryTestsRequired) {
               const firstTest = testsToProcess[0];
               setAvailableTests([firstTest]);
               navigate(ROUTE_INTERVIEW.PREVIEW_INTERVIEW);
            } else if (testsToProcess.length > 1 && interviewSettings.mandatoryTestsRequired) {
               setAvailableTests([...interviewTests]);
            } else if (testsToProcess.length === 1) {
               setAvailableTests([...interviewTests]);
               navigate(ROUTE_INTERVIEW.PREVIEW_INTERVIEW);
            }
            const currentTest = testsToProcess[testsToProcess.length - 1];
            setSkillId(currentTest);
         }
      }
   }, [isSuccessFetchInterviewTests]);

   useEffect(() => {
      if (isSuccessQuestions) {
         setQuestions(questionsData);
         setIsCamera(false);
         setStartInterview(true);

         const unansweredQuestions = questionsData.filter(el => el.status === 'There is not Response');

         let questions = unansweredQuestions?.map(el => el.question);

         if (unansweredQuestions.length > 0) {
            const currentQuestionIndex = questionsData.findIndex(el => el === unansweredQuestions[0]) + 1;

            setQuestions(questions)
            setIndexQuestion(currentQuestionIndex);
            setTotalQuestions(questionsData.length);

            setCurrentQuestion(unansweredQuestions[0].question?.question);
            onSetCurrentQuestion(unansweredQuestions[0].question?.question);

            onSetIsAnimationSpeech(true);
            if (availableTests.length > 1) {
               setStatusTimer(true);
               navigate(ROUTE_INTERVIEW.SHOW_ABOUTME_INTERVIEW);
            } else {
               setStatusTimer(true);
               navigate(ROUTE_INTERVIEW.RECORDING_INTERVIEW);
            }
         }
      }
   }, [isSuccessQuestions, questionsData]);

   const onStartTest = () => {
      onSetCurrentQuestion("");
      if (availableTests.length === 1) {
         triggerGetTasksQuery({
            _id: availableTests[0],
            secret: secret
         })
      } else if (availableTests.length > 1) {
         triggerGetTasksQuery({
            _id: availableTests[1],
            secret: secret
         })
      }
   }

   const onHandlePreviewTest = () => {
      triggerFetchInterviewTests({
         _id: testId,
         secret: secret
      })
   }

   const handleDeviceCameraChange = async (event) => {
      const selectedValue = event.target.value;
      setInputVideoSelected(selectedValue);
      onCameraSelection(selectedValue);
   };

   const onCameraSelection = async (selectedValue) => {
      try {
         const newStream = await navigator.mediaDevices.getUserMedia({
            video: {deviceId: selectedValue},
         });

         setStreamVideoState(newStream);
         setSelectedVideoDevice(selectedValue);

         if (videoRef.current) {
            videoRef.current.srcObject = newStream;
         }
      } catch (error) {
         console.error('Error al cambiar el dispositivo de video:', error);
      }
   }

   const handleChange = async (event) => {
      const selectedValue = event.target.value;
      setInputAudioSelected(selectedValue)
      virtualizeMicroPhone(selectedValue);
   }

   const virtualizeMicroPhone = async (selectedValue) => {
      try {
         setEnableMedia(true);
         const audioContext = new (window.AudioContext || window.webkitAudioContext)();
         const analyser = audioContext.createAnalyser();

         const stream = await navigator.mediaDevices.getUserMedia({
            audio: {deviceId: selectedValue},
         });

         setSelectedDevice(selectedValue);

         const source = audioContext.createMediaStreamSource(stream);

         source.connect(analyser);

         analyser.fftSize = 256;
         const bufferLength = analyser.frequencyBinCount;
         const dataArray = new Uint8Array(bufferLength);

         const updateVolume = () => {
            analyser.getByteFrequencyData(dataArray);
            const average = dataArray.reduce((acc, value) => acc + value, 0) / bufferLength;

            const normalizedVolume = average / 255;
            setVolume(normalizedVolume);

            if (visualizerRef.current) {
               visualizerRef.current.style.width = `${normalizedVolume * 100}%`;
            }

            requestAnimationFrame(updateVolume);
         };

         updateVolume();
      } catch (error) {
         console.error('Error al obtener acceso al micrófono:', error);
      }
   }

   return {
      availableTests,
      enableScreenInterviewQuestions,
      enableScreenAboutMeQuestions,
      videoRef,
      isCamera,
      audioStream,
      videoDevices,
      selectedVideoDevice,
      volume,
      selectedDevice,
      visualizerRef,
      enableMedia,
      streamVideo,
      //Fetch questions
      isFetchingQuestions,
      onStartTest,
      handleChange,
      handleDeviceCameraChange,
      onHandlePreviewTest
   }

}
export default useTestVideoController;
