import React, {lazy, Suspense} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {
   ROUTE_ADMIN_CANDIDATE,
   ROUTE_ADMIN_TEST_GENERATOR,
   ROUTE_ENDPOINTS,
   ROUTE_INTERVIEW
} from "../../data/constant/RouteConstants";
import useAuthViewModel from "../../view-models/useAuthViewModel";
import useInterviewViewModel from "../../view-models/useInterviewViewModel";
import TestManagement from "../pages/Admin/TestGenerator/TestManagement";
import {BreadcrumbProvider} from "../../store/context/BreadcrumbContext";
import PreviewAboutMeInterview from "../pages/Interview/PreviewAboutMeInterview";

const TestVideoView = lazy(() => import("../pages/Interview/TestVideo"));
const TestLandingView = lazy(() => import("../pages/Interview/TestLanding"));
const SharedInterviewLanding = lazy(() => import("../pages/Interview/SharedInterviewLanding"));
const TermsAndConditions = lazy(() => import("../pages/Interview/TermsAndConditions"));
const KeyIndicationsView = lazy(() => import("../pages/Interview/KeyIndications"));
const NotFoundPage = lazy(() => import("../pages/NotFoundPage"));
const Login = lazy(() => import("../pages/Auth/Login"));
const ForgotPassword = lazy(() => import("../pages/Auth/ForgotPassword"));
const Inbox = lazy(() => import("../pages/Auth/Inbox"));
const ResetPassword = lazy(() => import("../pages/Auth/ResetPassword"));
const RecordingInterview = lazy(() => import("../pages/Interview/RecordingInterview"));
const PersonalBasicInfo = lazy(() => import("../pages/Interview/PersonalBasicInfo"));
const ProfesionlInfo = lazy(() => import("../pages/Interview/ProfesionalInfo"));
const SelfAssesment = lazy(() => import("../pages/Interview/SelfAssesment"));
const InterviewFeedBack = lazy(() => import("../pages/Interview/InterviewFeedback"));
const ShowAboutmeInterview = lazy(() => import('../pages/Interview/ShowAboutmeInterview'));
const InterviewLayaut = lazy(() => import('../components/layout/InterviewLayout'))
const PreviewAboutmeScreen = lazy(() => import('../pages/Interview/PreviewAboutMeInterview/PreviewAboutMeInterview'));
const PreviewInterviewScreen = lazy(() => import('../pages/Interview/PreviewInterview'));

// ROUTES ADMIN TEST GENERATOR //
const TestList = lazy(() => import("../pages/Admin/TetsList"));
const TestGenerator = lazy(() => import("../pages/Admin/TestGenerator"));

// ROUTES ADMIN CANDIDATES //
const Dashboard = lazy(() => import("../pages/Admin/Dashboard"));
const AdminManage = lazy(() => import("../pages/Admin/AdminManage"));
const SuperAdmin = lazy(() => import("../pages/Admin/SuperAdmin"));
const AdminCompany = lazy(() => import("../pages/Admin/AdminCompany"));
const Home = lazy(() => import("../pages/Admin/Home"));
const ManageUser = lazy(() => import("../pages/Admin/ManageUser"));
const CandidatesList = lazy(() => import("../pages/Admin/Candidates/CandidatesList"));
const CandidateDetail = lazy(() => import("../pages/Admin/Candidates/CandidateDetail"));
const CandidateTestResult = lazy(() => import("../pages/Admin/Candidates/CandidateTestResult"));
const TestCompleted = lazy(() => import("../pages/Interview/TestCompleted"));
const UsersCompanyList = lazy(() => import("../pages/Admin/AdminCompany/UsersCompanyList"));

const Router = () => {

   const {token, user} = useAuthViewModel();
   const {enableRoute} = useInterviewViewModel();

   function PublicRoute(path, element) {
      return (
         <Route
            path={path}
            element={element}
         />
      )
   }

   function PrivateRoute(path, element) {
      return (
         <Route
            path={path}
            element={element}
         />
      )
   }

   return (
      <BrowserRouter>
         <BreadcrumbProvider>
            <Suspense fallback={<div>Loading...</div>}>
               <Routes>
                  {/*{PublicRoute(ROUTE_ENDPOINTS.LOGIN, <Login/>)}*/}
                  {!token && PublicRoute(ROUTE_ENDPOINTS.LOGIN, <Login/>)}
                  {PublicRoute(ROUTE_ENDPOINTS.CHECK_INBOX, <Inbox/>)}
                  {PublicRoute(ROUTE_ENDPOINTS.RESET_PASSWORD, <ResetPassword/>)}
                  {PublicRoute(ROUTE_ENDPOINTS.FORGOT_PASSWORD, <ForgotPassword/>)}
                  <Route path="interview" element={<InterviewLayaut/>}>
                     {PublicRoute(ROUTE_INTERVIEW.TEST_LANDING, <TestLandingView/>)}
                     {PublicRoute(ROUTE_INTERVIEW.TEST_LANDING_SKILL, <SharedInterviewLanding/>)}
                     {PublicRoute(ROUTE_INTERVIEW.TERMS_AND_CONDITIONS, <TermsAndConditions/>)}
                     {
                        enableRoute &&
                        <>
                           {PublicRoute(ROUTE_INTERVIEW.PERSONAL_BASIC_INFO, <PersonalBasicInfo/>)}
                           {PublicRoute(ROUTE_INTERVIEW.PROFESSIONAL_INFO, <ProfesionlInfo/>)}
                        </>
                     }
                     {PublicRoute(ROUTE_INTERVIEW.KEY_INDICATIONS, <KeyIndicationsView/>)}
                     {PublicRoute(ROUTE_INTERVIEW.TEST_VIDEO, <TestVideoView/>)}
                     {PublicRoute(ROUTE_INTERVIEW.PREVIEW_ABOUT_ME_INTERVIEW, <PreviewAboutmeScreen/>)}
                     {PublicRoute(ROUTE_INTERVIEW.PREVIEW_INTERVIEW, <PreviewInterviewScreen/>)}
                     {PublicRoute(ROUTE_INTERVIEW.RECORDING_INTERVIEW, <RecordingInterview/>)}
                     {PublicRoute(ROUTE_INTERVIEW.SHOW_ABOUTME_INTERVIEW, <ShowAboutmeInterview/>)}
                     {PublicRoute(ROUTE_INTERVIEW.SELF_ASSESMENT, <SelfAssesment/>)}
                     {PublicRoute(ROUTE_INTERVIEW.INTERVIEW_FEEDBACK, <InterviewFeedBack/>)}
                     {PublicRoute(ROUTE_INTERVIEW.TEST_COMPLETED, <TestCompleted/>)}
                  </Route>
                  {token && (
                     <Route path="" element={<Home/>}>
                        {PrivateRoute("/", <Navigate to={ROUTE_ADMIN_CANDIDATE.DASHBOARD}/>)}
                        {PrivateRoute(ROUTE_ADMIN_CANDIDATE.DASHBOARD, <Dashboard/>)}
                        {PrivateRoute(ROUTE_ADMIN_TEST_GENERATOR.TEST_LIST, <TestList/>)}
                        {PrivateRoute(ROUTE_ADMIN_TEST_GENERATOR.TEST_GENERATOR, <TestGenerator/>)}
                        {PrivateRoute(ROUTE_ADMIN_TEST_GENERATOR.TEST_GENERATOR_UPDATE, <TestManagement/>)}
                        {PrivateRoute(ROUTE_ADMIN_CANDIDATE.CANDIDATE_LIST, <CandidatesList/>)}/
                        {PrivateRoute(ROUTE_ADMIN_CANDIDATE.CANDIDATE_DETAIL, <CandidateDetail/>)}
                        {PrivateRoute(ROUTE_ADMIN_CANDIDATE.CANDIDATE_TEST_RESULTS, <CandidateTestResult/>)}
                        {PrivateRoute(ROUTE_ADMIN_CANDIDATE.ADMIN_MANAGE, <AdminManage/>)}
                        {PublicRoute(ROUTE_ADMIN_CANDIDATE.SUPER_ADMIN, <SuperAdmin/>)}
                        {PublicRoute(ROUTE_ADMIN_CANDIDATE.ADMIN_COMPANY, <AdminCompany/>)}
                        {PublicRoute(ROUTE_ADMIN_CANDIDATE.MANAGE_USER, <ManageUser/>)}
                        {PublicRoute(ROUTE_ADMIN_CANDIDATE.MANAGE_USER_EDIT, <ManageUser/>)}
                        {PublicRoute(ROUTE_ADMIN_CANDIDATE.USERS_BY_COMPANY, <UsersCompanyList/>)}
                        {/*{PublicRoute(ROUTE_ENDPOINTS.TERMS_AND_CONDITIONS, <TermsAndConditions/>)}*/}
                        {/*{PublicRoute(ROUTE_ENDPOINTS.KEY_INDICATIONS, <KeyIndicationsView/>)}*/}
                        {/*{PublicRoute(ROUTE_ENDPOINTS.TEST_VIDEO, <TestVideoView/>)}*/}
                        {/*{PublicRoute(ROUTE_ENDPOINTS.PERSONAL_BASIC_INFO, <PersonalBasicInfo/>)}*/}
                        {/*{PublicRoute(ROUTE_ENDPOINTS.PROFESSIONAL_INFO, <ProfesionlInfo/>)}*/}
                     </Route>
                  )}
                  {!token && PublicRoute("*", <Navigate to={ROUTE_ENDPOINTS.LOGIN}/>)}
                  {PublicRoute("*", <NotFoundPage/>)}
               </Routes>
            </Suspense>
         </BreadcrumbProvider>
      </BrowserRouter>
   )

}
export default Router;
