import {Box} from "@mui/material";
import CustomTypography from "../../../components/ui/Typography";
import {useEffect, useState} from "react";
import dayjs from "dayjs";

const QuestionHistory = ({isVisible, data, indexQuestion, indexHistory, onClick}) => {
   const [historyQuestions, setHistoryQuestions] = useState([]);
   const [hideHistory, setHideHistory] = useState([]);

   useEffect(() => {
      const newHistory = (array, index) => {
         return array?.reduce((acc, el) => {
            if (el?.originalQuestion?.text.startsWith(`${index}.`)) {
               acc.push(el);
            }
            return acc;
         }, []);
      };

      const result = newHistory(data?.modifiedQuestions, indexQuestion + 1);
      setHistoryQuestions(result);

      const newVersionHideButton = (array, index) => {
         return array?.reduce((acc, el) => {
            if (parseInt(el?.originalQuestion?.text?.match(/^\d+/)?.[0]) === index) {
               if (!acc.some(item => item.indexElement === index + 1)) {
                  acc.push({
                     indexElement: index + 1,
                     element: (
                        <CustomTypography
                           title={isVisible[indexQuestion] ? "Hide version history" : "Show version history"}
                           sx={{ cursor: 'pointer', color: 'primary.main', my: 1 }}
                           onClick={() => onClick(indexQuestion)}
                        />
                     ),
                  });
               }
            }
            return acc;
         }, []);
      };

      const newButton = newVersionHideButton(data?.modifiedQuestions, indexQuestion + 1);
      setHideHistory(newButton);

   }, [data, indexQuestion, onClick, isVisible]);

   return (
      <Box sx={{ mb: 3 }}>
         {hideHistory?.map((el, index) => (
            <Box key={index}>{el?.element}</Box>
         ))}
         {historyQuestions?.map((el, index) => (
            <Box key={index} sx={{ display: 'flex' }}>
               {isVisible[indexQuestion] && (
                  <>
                     <Box
                        sx={{
                           height: 46,
                           width: 4,
                           background: '#ddd',
                           flex: '0 0 auto',
                        }}
                     ></Box>
                     <Box p={1} pl={2}>
                        <CustomTypography
                           title={el?.originalQuestion?.text.replace(/^\d+\.\s*/, '')}
                           fontSize={12}
                           fontWeight={400}
                        />
                        <Box sx={{ display: 'flex', mb: 1 }}>
                           <CustomTypography title={"Edited by:"} fontSize={12} fontWeight={400} />
                           <CustomTypography
                              sx={{ color: 'primary.main', mx: '5px' }}
                              title={el?.editedBy}
                              fontWeight={400}
                              fontSize={12}
                           />
                           <CustomTypography
                              fontSize={12}
                              fontWeight={400}
                              title={dayjs(el?.modifiedDate).format('MM/DD/YYYY')}
                           />
                        </Box>
                     </Box>
                  </>
               )}
            </Box>
         ))}
      </Box>
   );
};
export default QuestionHistory;
