import {useLazyFetchInterviewTestsQuery, useLazyGetTasksQuery} from "../store/app/apiSlice/apiSlice";
import {useDispatch, useSelector} from "react-redux";
import {
   setCurrentQuestion,
   setIndexQuestion,
   setQuestions,
   setStatusTimer,
   setTotalQuestions,
   setSkillId,
   setAvailableTests
} from "../store/features/interview-slilce";

const useTestMultimediaViewModel = () => {

   const dispatch = useDispatch();

   const {
      testId,
      availableTests,
      idSkill,
      secret,
      totalQuestions
   } = useSelector(state => state.InterviewReducer)

   const [triggerGetTasksQuery, {
      data: questionsData,
      isSuccess: isSuccessQuestions,
      isLoading: isLoadingQuestions,
      isFetching: isFetchingQuestions,
      isError: isErrorFetchQuestions
   }] = useLazyGetTasksQuery();

   const [triggerFetchInterviewTests, {
      data: interviewTests,
      isSuccess: isSuccessFetchInterviewTests,
      isLoading: isLoadingFetchInterviewTests,
      isFetching: isFetchingInterviewTests,
      isError: isErrorFetchingInterviewTests
   }] = useLazyFetchInterviewTestsQuery()

   return {
      //Get available interview
      triggerFetchInterviewTests,
      interviewTests,
      isSuccessFetchInterviewTests,
      isFetchingInterviewTests,
      isErrorFetchingInterviewTests,
      //Fetching questions
      triggerGetTasksQuery,
      questionsData,
      isSuccessQuestions,
      isLoadingQuestions,
      isFetchingQuestions,
      isErrorFetchQuestions,
      //State
      idSkill,
      testId,
      secret,
      totalQuestions,
      availableTests,
      setIndexQuestion: (index) => dispatch(setIndexQuestion(index)),
      setCurrentQuestion: (question) => dispatch(setCurrentQuestion(question)),
      setQuestions: (questions) => dispatch(setQuestions(questions)),
      setTotalQuestions: (quantity) => dispatch(setTotalQuestions(quantity)),
      setStatusTimer: (status) => dispatch(setStatusTimer(status)),
      setSkillId: (skillId) => dispatch(setSkillId(skillId)),
      setAvailableTests: (tests) => dispatch(setAvailableTests(tests)),
   }
}
export default useTestMultimediaViewModel;
